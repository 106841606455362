export const API_URL = '/api'
export const BASE_URL = '/residents'

export const API_ROUTES = {
  SIGN_IN: `/auth/login`,
  SIGN_OUT: `/auth/logout`,
  GET_USER: `/me/`,
  REFRESH_TOKEN: `/auth/renew`,
  CONTRACTS: `/contracts`,
  ANNOUNCEMENTS: `/announcements`,
  REQUESTS: `/requests`,
  RESDIENTS: `/residents`,
  VEHICLES: `/vehicles`,
  PETS: `/pets`,
  PROFILE: `/me/profile`,
  FORGOT_PASSWORD: `/auth/forgot-password`,
  RESET_PASSWORD: `/auth/reset-password`,
}

export const APP_ROUTES = {
  HOME: "/",
  SIGN_IN: '/login',
}

export const IMG_type = "image"
export const FloorPlan_type = "floor_plan"
export const ALLOWED_IMAGES_TYPE = ['.jpeg', '.png', '.jpg']
export const ALLOWED_FILES_TYPE = [".pdf", ".png", ".jpg", ".jpeg"]
export const MAX_FILE_SIZE_MB = 5
export const MAX_IMAGE_SIZE_MB = 2
export const CALENDAR_MONTHS_UPPER_LIMIT = 1

export const DAYS_OF_WEEK = [
  { day: 'sunday', code: 'SU' },
  { day: 'monday', code: 'MO' },
  { day: 'tuesday', code: 'TU' },
  { day: 'wednesday', code: 'WE' },
  { day: 'thursday', code: 'TH' },
  { day: 'friday', code: 'FR' },
  { day: 'saturday', code: 'SA' }
];

export const CALENDAR_YEARS_UPPER_LIMIT = 5;

export const phoneInputLabelsAr = {

  "country": "البلد",
  "phone": "رقم الهاتف",
  "ext": "التحويلة",
  "ZZ": "العالم",
  "AC": "\u062c\u0632\u064a\u0631\u0629 \u0623\u0633\u064a\u0646\u0634\u0646",
  "TA": "\u062a\u0631\u064a\u0633\u062a\u0627\u0646 \u062f\u0627 \u0643\u0648\u0646\u0627",
  "XK": "\u0643\u0648\u0633\u0648\u0641\u0648",
  "IS": "\u0622\u064a\u0633\u0644\u0646\u062f\u0627",
  "ET": "\u0625\u062b\u064a\u0648\u0628\u064a\u0627",
  "AZ": "\u0623\u0630\u0631\u0628\u064a\u062c\u0627\u0646",
  "AM": "\u0623\u0631\u0645\u064a\u0646\u064a\u0627",
  "AW": "\u0623\u0631\u0648\u0628\u0627",
  "ER": "\u0625\u0631\u064a\u062a\u0631\u064a\u0627",
  "ES": "\u0625\u0633\u0628\u0627\u0646\u064a\u0627",
  "AU": "\u0623\u0633\u062a\u0631\u0627\u0644\u064a\u0627",
  "EE": "\u0625\u0633\u062a\u0648\u0646\u064a\u0627",
  "SZ": "\u0625\u0633\u0648\u0627\u062a\u064a\u0646\u064a",
  "AF": "\u0623\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646",
  "PS": "\u0641\u0644\u0633\u0637\u064a\u0646",
  "AR": "\u0627\u0644\u0623\u0631\u062c\u0646\u062a\u064a\u0646",
  "JO": "\u0627\u0644\u0623\u0631\u062f\u0646",
  "TF": "\u0627\u0644\u0623\u0642\u0627\u0644\u064a\u0645 \u0627\u0644\u062c\u0646\u0648\u0628\u064a\u0629 \u0627\u0644\u0641\u0631\u0646\u0633\u064a\u0629",
  "IO": "\u0627\u0644\u0625\u0642\u0644\u064a\u0645 \u0627\u0644\u0628\u0631\u064a\u0637\u0627\u0646\u064a \u0641\u064a \u0627\u0644\u0645\u062d\u064a\u0637 \u0627\u0644\u0647\u0646\u062f\u064a",
  "EC": "\u0627\u0644\u0625\u0643\u0648\u0627\u062f\u0648\u0631",
  "AE": "\u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
  "AL": "\u0623\u0644\u0628\u0627\u0646\u064a\u0627",
  "BH": "\u0627\u0644\u0628\u062d\u0631\u064a\u0646",
  "BR": "\u0627\u0644\u0628\u0631\u0627\u0632\u064a\u0644",
  "PT": "\u0627\u0644\u0628\u0631\u062a\u063a\u0627\u0644",
  "BA": "\u0627\u0644\u0628\u0648\u0633\u0646\u0629 \u0648\u0627\u0644\u0647\u0631\u0633\u0643",
  "CZ": "\u0627\u0644\u062a\u0634\u064a\u0643",
  "ME": "\u0627\u0644\u062c\u0628\u0644 \u0627\u0644\u0623\u0633\u0648\u062f",
  "DZ": "\u0627\u0644\u062c\u0632\u0627\u0626\u0631",
  "DK": "\u0627\u0644\u062f\u0627\u0646\u0645\u0631\u0643",
  "CV": "\u0627\u0644\u0631\u0623\u0633 \u0627\u0644\u0623\u062e\u0636\u0631",
  "SV": "\u0627\u0644\u0633\u0644\u0641\u0627\u062f\u0648\u0631",
  "SN": "\u0627\u0644\u0633\u0646\u063a\u0627\u0644",
  "SD": "\u0627\u0644\u0633\u0648\u062f\u0627\u0646",
  "SE": "\u0627\u0644\u0633\u0648\u064a\u062f",
  "EH": "\u0627\u0644\u0635\u062d\u0631\u0627\u0621 \u0627\u0644\u063a\u0631\u0628\u064a\u0629",
  "SO": "\u0627\u0644\u0635\u0648\u0645\u0627\u0644",
  "CN": "\u0627\u0644\u0635\u064a\u0646",
  "IQ": "\u0627\u0644\u0639\u0631\u0627\u0642",
  "GA": "\u0627\u0644\u063a\u0627\u0628\u0648\u0646",
  "VA": "\u0627\u0644\u0641\u0627\u062a\u064a\u0643\u0627\u0646",
  "PH": "\u0627\u0644\u0641\u0644\u0628\u064a\u0646",
  "CM": "\u0627\u0644\u0643\u0627\u0645\u064a\u0631\u0648\u0646",
  "CG": "\u0627\u0644\u0643\u0648\u0646\u063a\u0648 - \u0628\u0631\u0627\u0632\u0627\u0641\u064a\u0644",
  "CD": "\u0627\u0644\u0643\u0648\u0646\u063a\u0648 - \u0643\u064a\u0646\u0634\u0627\u0633\u0627",
  "KW": "\u0627\u0644\u0643\u0648\u064a\u062a",
  "DE": "\u0623\u0644\u0645\u0627\u0646\u064a\u0627",
  "MA": "\u0627\u0644\u0645\u063a\u0631\u0628",
  "MX": "\u0627\u0644\u0645\u0643\u0633\u064a\u0643",
  "SA": "\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629",
  "GB": "\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
  "NO": "\u0627\u0644\u0646\u0631\u0648\u064a\u062c",
  "AT": "\u0627\u0644\u0646\u0645\u0633\u0627",
  "NE": "\u0627\u0644\u0646\u064a\u062c\u0631",
  "IN": "\u0627\u0644\u0647\u0646\u062f",
  "US": "\u0627\u0644\u0648\u0644\u0627\u064a\u0627\u062a \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
  "JP": "\u0627\u0644\u064a\u0627\u0628\u0627\u0646",
  "YE": "\u0627\u0644\u064a\u0645\u0646",
  "GR": "\u0627\u0644\u064a\u0648\u0646\u0627\u0646",
  "AQ": "\u0623\u0646\u062a\u0627\u0631\u0643\u062a\u064a\u0643\u0627",
  "AG": "\u0623\u0646\u062a\u064a\u063a\u0648\u0627 \u0648\u0628\u0631\u0628\u0648\u062f\u0627",
  "AD": "\u0623\u0646\u062f\u0648\u0631\u0627",
  "ID": "\u0625\u0646\u062f\u0648\u0646\u064a\u0633\u064a\u0627",
  "AO": "\u0623\u0646\u063a\u0648\u0644\u0627",
  "AI": "\u0623\u0646\u063a\u0648\u064a\u0644\u0627",
  "UY": "\u0623\u0648\u0631\u063a\u0648\u0627\u064a",
  "UZ": "\u0623\u0648\u0632\u0628\u0643\u0633\u062a\u0627\u0646",
  "UG": "\u0623\u0648\u063a\u0646\u062f\u0627",
  "UA": "\u0623\u0648\u0643\u0631\u0627\u0646\u064a\u0627",
  "IR": "\u0625\u064a\u0631\u0627\u0646",
  "IE": "\u0623\u064a\u0631\u0644\u0646\u062f\u0627",
  "IT": "\u0625\u064a\u0637\u0627\u0644\u064a\u0627",
  "PG": "\u0628\u0627\u0628\u0648\u0627 \u063a\u064a\u0646\u064a\u0627 \u0627\u0644\u062c\u062f\u064a\u062f\u0629",
  "PY": "\u0628\u0627\u0631\u0627\u063a\u0648\u0627\u064a",
  "PK": "\u0628\u0627\u0643\u0633\u062a\u0627\u0646",
  "PW": "\u0628\u0627\u0644\u0627\u0648",
  "BB": "\u0628\u0631\u0628\u0627\u062f\u0648\u0633",
  "BM": "\u0628\u0631\u0645\u0648\u062f\u0627",
  "BN": "\u0628\u0631\u0648\u0646\u0627\u064a",
  "BE": "\u0628\u0644\u062c\u064a\u0643\u0627",
  "BG": "\u0628\u0644\u063a\u0627\u0631\u064a\u0627",
  "BZ": "\u0628\u0644\u064a\u0632",
  "BD": "\u0628\u0646\u063a\u0644\u0627\u062f\u064a\u0634",
  "PA": "\u0628\u0646\u0645\u0627",
  "BJ": "\u0628\u0646\u064a\u0646",
  "BT": "\u0628\u0648\u062a\u0627\u0646",
  "BW": "\u0628\u0648\u062a\u0633\u0648\u0627\u0646\u0627",
  "PR": "\u0628\u0648\u0631\u062a\u0648\u0631\u064a\u0643\u0648",
  "BF": "\u0628\u0648\u0631\u0643\u064a\u0646\u0627 \u0641\u0627\u0633\u0648",
  "BI": "\u0628\u0648\u0631\u0648\u0646\u062f\u064a",
  "PL": "\u0628\u0648\u0644\u0646\u062f\u0627",
  "BO": "\u0628\u0648\u0644\u064a\u0641\u064a\u0627",
  "PF": "\u0628\u0648\u0644\u064a\u0646\u064a\u0632\u064a\u0627 \u0627\u0644\u0641\u0631\u0646\u0633\u064a\u0629",
  "PE": "\u0628\u064a\u0631\u0648",
  "BY": "\u0628\u064a\u0644\u0627\u0631\u0648\u0633",
  "TH": "\u062a\u0627\u064a\u0644\u0627\u0646\u062f",
  "TW": "\u062a\u0627\u064a\u0648\u0627\u0646",
  "TM": "\u062a\u0631\u0643\u0645\u0627\u0646\u0633\u062a\u0627\u0646",
  "TR": "\u062a\u0631\u0643\u064a\u0627",
  "TT": "\u062a\u0631\u064a\u0646\u064a\u062f\u0627\u062f \u0648\u062a\u0648\u0628\u0627\u063a\u0648",
  "TD": "\u062a\u0634\u0627\u062f",
  "CL": "\u062a\u0634\u064a\u0644\u064a",
  "TZ": "\u062a\u0646\u0632\u0627\u0646\u064a\u0627",
  "TG": "\u062a\u0648\u063a\u0648",
  "TV": "\u062a\u0648\u0641\u0627\u0644\u0648",
  "TK": "\u062a\u0648\u0643\u064a\u0644\u0648",
  "TN": "\u062a\u0648\u0646\u0633",
  "TO": "\u062a\u0648\u0646\u063a\u0627",
  "TL": "\u062a\u064a\u0645\u0648\u0631 - \u0644\u064a\u0634\u062a\u064a",
  "JM": "\u062c\u0627\u0645\u0627\u064a\u0643\u0627",
  "GI": "\u062c\u0628\u0644 \u0637\u0627\u0631\u0642",
  "AX": "\u062c\u0632\u0631 \u0622\u0644\u0627\u0646\u062f",
  "BS": "\u062c\u0632\u0631 \u0627\u0644\u0628\u0647\u0627\u0645\u0627",
  "KM": "\u062c\u0632\u0631 \u0627\u0644\u0642\u0645\u0631",
  "MQ": "\u062c\u0632\u0631 \u0627\u0644\u0645\u0627\u0631\u062a\u064a\u0646\u064a\u0643",
  "MV": "\u062c\u0632\u0631 \u0627\u0644\u0645\u0627\u0644\u062f\u064a\u0641",
  "UM": "\u062c\u0632\u0631 \u0627\u0644\u0648\u0644\u0627\u064a\u0627\u062a \u0627\u0644\u0645\u062a\u062d\u062f\u0629 \u0627\u0644\u0646\u0627\u0626\u064a\u0629",
  "PN": "\u062c\u0632\u0631 \u0628\u064a\u062a\u0643\u064a\u0631\u0646",
  "TC": "\u062c\u0632\u0631 \u062a\u0648\u0631\u0643\u0633 \u0648\u0643\u0627\u064a\u0643\u0648\u0633",
  "SB": "\u062c\u0632\u0631 \u0633\u0644\u064a\u0645\u0627\u0646",
  "FO": "\u062c\u0632\u0631 \u0641\u0627\u0631\u0648",
  "FK": "\u062c\u0632\u0631 \u0641\u0648\u0643\u0644\u0627\u0646\u062f",
  "VG": "\u062c\u0632\u0631 \u0641\u064a\u0631\u062c\u0646 \u0627\u0644\u0628\u0631\u064a\u0637\u0627\u0646\u064a\u0629",
  "VI": "\u062c\u0632\u0631 \u0641\u064a\u0631\u062c\u0646 \u0627\u0644\u062a\u0627\u0628\u0639\u0629 \u0644\u0644\u0648\u0644\u0627\u064a\u0627\u062a \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
  "KY": "\u062c\u0632\u0631 \u0643\u0627\u064a\u0645\u0627\u0646",
  "CK": "\u062c\u0632\u0631 \u0643\u0648\u0643",
  "CC": "\u062c\u0632\u0631 \u0643\u0648\u0643\u0648\u0633 (\u0643\u064a\u0644\u064a\u0646\u063a)",
  "MH": "\u062c\u0632\u0631 \u0645\u0627\u0631\u0634\u0627\u0644",
  "MP": "\u062c\u0632\u0631 \u0645\u0627\u0631\u064a\u0627\u0646\u0627 \u0627\u0644\u0634\u0645\u0627\u0644\u064a\u0629",
  "WF": "\u062c\u0632\u0631 \u0648\u0627\u0644\u0633 \u0648\u0641\u0648\u062a\u0648\u0646\u0627",
  "BV": "\u062c\u0632\u064a\u0631\u0629 \u0628\u0648\u0641\u064a\u0647",
  "CX": "\u062c\u0632\u064a\u0631\u0629 \u0643\u0631\u064a\u0633\u0645\u0627\u0633",
  "IM": "\u062c\u0632\u064a\u0631\u0629 \u0645\u0627\u0646",
  "NF": "\u062c\u0632\u064a\u0631\u0629 \u0646\u0648\u0631\u0641\u0648\u0644\u0643",
  "HM": "\u062c\u0632\u064a\u0631\u0629 \u0647\u064a\u0631\u062f \u0648\u062c\u0632\u0631 \u0645\u0627\u0643\u062f\u0648\u0646\u0627\u0644\u062f",
  "CF": "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0623\u0641\u0631\u064a\u0642\u064a\u0627 \u0627\u0644\u0648\u0633\u0637\u0649",
  "DO": "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u062f\u0648\u0645\u064a\u0646\u064a\u0643\u0627\u0646",
  "ZA": "\u062c\u0646\u0648\u0628 \u0623\u0641\u0631\u064a\u0642\u064a\u0627",
  "SS": "\u062c\u0646\u0648\u0628 \u0627\u0644\u0633\u0648\u062f\u0627\u0646",
  "GE": "\u062c\u0648\u0631\u062c\u064a\u0627",
  "GS": "\u062c\u0648\u0631\u062c\u064a\u0627 \u0627\u0644\u062c\u0646\u0648\u0628\u064a\u0629 \u0648\u062c\u0632\u0631 \u0633\u0627\u0646\u062f\u0648\u064a\u062a\u0634 \u0627\u0644\u062c\u0646\u0648\u0628\u064a\u0629",
  "DJ": "\u062c\u064a\u0628\u0648\u062a\u064a",
  "JE": "\u062c\u064a\u0631\u0633\u064a",
  "DM": "\u062f\u0648\u0645\u064a\u0646\u064a\u0643\u0627",
  "RW": "\u0631\u0648\u0627\u0646\u062f\u0627",
  "RU": "\u0631\u0648\u0633\u064a\u0627",
  "RO": "\u0631\u0648\u0645\u0627\u0646\u064a\u0627",
  "RE": "\u0631\u0648\u064a\u0646\u064a\u0648\u0646",
  "ZM": "\u0632\u0627\u0645\u0628\u064a\u0627",
  "ZW": "\u0632\u064a\u0645\u0628\u0627\u0628\u0648\u064a",
  "CI": "\u0633\u0627\u062d\u0644 \u0627\u0644\u0639\u0627\u062c",
  "WS": "\u0633\u0627\u0645\u0648\u0627",
  "AS": "\u0633\u0627\u0645\u0648\u0627 \u0627\u0644\u0623\u0645\u0631\u064a\u0643\u064a\u0629",
  "BL": "\u0633\u0627\u0646 \u0628\u0627\u0631\u062a\u0644\u064a\u0645\u064a",
  "PM": "\u0633\u0627\u0646 \u0628\u064a\u064a\u0631 \u0648\u0645\u0643\u0648\u064a\u0644\u0648\u0646",
  "MF": "\u0633\u0627\u0646 \u0645\u0627\u0631\u062a\u0646",
  "SM": "\u0633\u0627\u0646 \u0645\u0627\u0631\u064a\u0646\u0648",
  "VC": "\u0633\u0627\u0646\u062a \u0641\u0646\u0633\u0646\u062a \u0648\u062c\u0632\u0631 \u063a\u0631\u064a\u0646\u0627\u062f\u064a\u0646",
  "KN": "\u0633\u0627\u0646\u062a \u0643\u064a\u062a\u0633 \u0648\u0646\u064a\u0641\u064a\u0633",
  "LC": "\u0633\u0627\u0646\u062a \u0644\u0648\u0633\u064a\u0627",
  "SX": "\u0633\u0627\u0646\u062a \u0645\u0627\u0631\u062a\u0646",
  "SH": "\u0633\u0627\u0646\u062a \u0647\u064a\u0644\u064a\u0646\u0627",
  "ST": "\u0633\u0627\u0648 \u062a\u0648\u0645\u064a \u0648\u0628\u0631\u064a\u0646\u0633\u064a\u0628\u064a",
  "LK": "\u0633\u0631\u064a\u0644\u0627\u0646\u0643\u0627",
  "SJ": "\u0633\u0641\u0627\u0644\u0628\u0627\u0631\u062f \u0648\u062c\u0627\u0646 \u0645\u0627\u064a\u0646",
  "SK": "\u0633\u0644\u0648\u0641\u0627\u0643\u064a\u0627",
  "SI": "\u0633\u0644\u0648\u0641\u064a\u0646\u064a\u0627",
  "SG": "\u0633\u0646\u063a\u0627\u0641\u0648\u0631\u0629",
  "SY": "\u0633\u0648\u0631\u064a\u0627",
  "SR": "\u0633\u0648\u0631\u064a\u0646\u0627\u0645",
  "CH": "\u0633\u0648\u064a\u0633\u0631\u0627",
  "SL": "\u0633\u064a\u0631\u0627\u0644\u064a\u0648\u0646",
  "SC": "\u0633\u064a\u0634\u0644",
  "RS": "\u0635\u0631\u0628\u064a\u0627",
  "TJ": "\u0637\u0627\u062c\u064a\u0643\u0633\u062a\u0627\u0646",
  "OM": "\u0639\u064f\u0645\u0627\u0646",
  "GM": "\u063a\u0627\u0645\u0628\u064a\u0627",
  "GH": "\u063a\u0627\u0646\u0627",
  "GD": "\u063a\u0631\u064a\u0646\u0627\u062f\u0627",
  "GL": "\u063a\u0631\u064a\u0646\u0644\u0627\u0646\u062f",
  "GT": "\u063a\u0648\u0627\u062a\u064a\u0645\u0627\u0644\u0627",
  "GP": "\u063a\u0648\u0627\u062f\u0644\u0648\u0628",
  "GU": "\u063a\u0648\u0627\u0645",
  "GF": "\u063a\u0648\u064a\u0627\u0646\u0627 \u0627\u0644\u0641\u0631\u0646\u0633\u064a\u0629",
  "GY": "\u063a\u064a\u0627\u0646\u0627",
  "GG": "\u063a\u064a\u0631\u0646\u0632\u064a",
  "GN": "\u063a\u064a\u0646\u064a\u0627",
  "GQ": "\u063a\u064a\u0646\u064a\u0627 \u0627\u0644\u0627\u0633\u062a\u0648\u0627\u0626\u064a\u0629",
  "GW": "\u063a\u064a\u0646\u064a\u0627 \u0628\u064a\u0633\u0627\u0648",
  "VU": "\u0641\u0627\u0646\u0648\u0627\u062a\u0648",
  "FR": "\u0641\u0631\u0646\u0633\u0627",
  "VE": "\u0641\u0646\u0632\u0648\u064a\u0644\u0627",
  "FI": "\u0641\u0646\u0644\u0646\u062f\u0627",
  "VN": "\u0641\u064a\u062a\u0646\u0627\u0645",
  "FJ": "\u0641\u064a\u062c\u064a",
  "CY": "\u0642\u0628\u0631\u0635",
  "QA": "\u0642\u0637\u0631",
  "KG": "\u0642\u064a\u0631\u063a\u064a\u0632\u0633\u062a\u0627\u0646",
  "KZ": "\u0643\u0627\u0632\u0627\u062e\u0633\u062a\u0627\u0646",
  "NC": "\u0643\u0627\u0644\u064a\u062f\u0648\u0646\u064a\u0627 \u0627\u0644\u062c\u062f\u064a\u062f\u0629",
  "HR": "\u0643\u0631\u0648\u0627\u062a\u064a\u0627",
  "KH": "\u0643\u0645\u0628\u0648\u062f\u064a\u0627",
  "CA": "\u0643\u0646\u062f\u0627",
  "CU": "\u0643\u0648\u0628\u0627",
  "CW": "\u0643\u0648\u0631\u0627\u0633\u0627\u0648",
  "KR": "\u0643\u0648\u0631\u064a\u0627 \u0627\u0644\u062c\u0646\u0648\u0628\u064a\u0629",
  "KP": "\u0643\u0648\u0631\u064a\u0627 \u0627\u0644\u0634\u0645\u0627\u0644\u064a\u0629",
  "CR": "\u0643\u0648\u0633\u062a\u0627\u0631\u064a\u0643\u0627",
  "CO": "\u0643\u0648\u0644\u0648\u0645\u0628\u064a\u0627",
  "KI": "\u0643\u064a\u0631\u064a\u0628\u0627\u062a\u064a",
  "KE": "\u0643\u064a\u0646\u064a\u0627",
  "LV": "\u0644\u0627\u062a\u0641\u064a\u0627",
  "LA": "\u0644\u0627\u0648\u0633",
  "LB": "\u0644\u0628\u0646\u0627\u0646",
  "LU": "\u0644\u0648\u0643\u0633\u0645\u0628\u0648\u0631\u063a",
  "LY": "\u0644\u064a\u0628\u064a\u0627",
  "LR": "\u0644\u064a\u0628\u064a\u0631\u064a\u0627",
  "LT": "\u0644\u064a\u062a\u0648\u0627\u0646\u064a\u0627",
  "LI": "\u0644\u064a\u062e\u062a\u0646\u0634\u062a\u0627\u064a\u0646",
  "LS": "\u0644\u064a\u0633\u0648\u062a\u0648",
  "MT": "\u0645\u0627\u0644\u0637\u0627",
  "ML": "\u0645\u0627\u0644\u064a",
  "MY": "\u0645\u0627\u0644\u064a\u0632\u064a\u0627",
  "YT": "\u0645\u0627\u064a\u0648\u062a",
  "MG": "\u0645\u062f\u063a\u0634\u0642\u0631",
  "EG": "\u0645\u0635\u0631",
  "MK": "\u0645\u0642\u062f\u0648\u0646\u064a\u0627 \u0627\u0644\u0634\u0645\u0627\u0644\u064a\u0629",
  "MW": "\u0645\u0644\u0627\u0648\u064a",
  "MO": "\u0645\u0646\u0637\u0642\u0629 \u0645\u0627\u0643\u0627\u0648 \u0627\u0644\u0625\u062f\u0627\u0631\u064a\u0629 \u0627\u0644\u062e\u0627\u0635\u0629",
  "MN": "\u0645\u0646\u063a\u0648\u0644\u064a\u0627",
  "MR": "\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627",
  "MU": "\u0645\u0648\u0631\u064a\u0634\u064a\u0648\u0633",
  "MZ": "\u0645\u0648\u0632\u0645\u0628\u064a\u0642",
  "MD": "\u0645\u0648\u0644\u062f\u0648\u0641\u0627",
  "MC": "\u0645\u0648\u0646\u0627\u0643\u0648",
  "MS": "\u0645\u0648\u0646\u062a\u0633\u0631\u0627\u062a",
  "MM": "\u0645\u064a\u0627\u0646\u0645\u0627\u0631 (\u0628\u0648\u0631\u0645\u0627)",
  "FM": "\u0645\u064a\u0643\u0631\u0648\u0646\u064a\u0632\u064a\u0627",
  "NA": "\u0646\u0627\u0645\u064a\u0628\u064a\u0627",
  "NR": "\u0646\u0627\u0648\u0631\u0648",
  "NP": "\u0646\u064a\u0628\u0627\u0644",
  "NG": "\u0646\u064a\u062c\u064a\u0631\u064a\u0627",
  "NI": "\u0646\u064a\u0643\u0627\u0631\u0627\u063a\u0648\u0627",
  "NZ": "\u0646\u064a\u0648\u0632\u064a\u0644\u0646\u062f\u0627",
  "NU": "\u0646\u064a\u0648\u064a",
  "HT": "\u0647\u0627\u064a\u062a\u064a",
  "HN": "\u0647\u0646\u062f\u0648\u0631\u0627\u0633",
  "HU": "\u0647\u0646\u063a\u0627\u0631\u064a\u0627",
  "NL": "\u0647\u0648\u0644\u0646\u062f\u0627",
  "BQ": "\u0647\u0648\u0644\u0646\u062f\u0627 \u0627\u0644\u0643\u0627\u0631\u064a\u0628\u064a\u0629",
  "HK": "\u0647\u0648\u0646\u063a \u0643\u0648\u0646\u063a \u0627\u0644\u0635\u064a\u0646\u064a\u0629 (\u0645\u0646\u0637\u0642\u0629 \u0625\u062f\u0627\u0631\u064a\u0629 \u062e\u0627\u0635\u0629)"
}